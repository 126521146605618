import {RenderData} from "../render_data/render_data";
import {getRenderDataCopy} from "../../../data/copy_data";
import {Segment} from "../render_data/enum_render";
import {WindowFragment} from "../window/window_fragment"
import {PanelFragment} from "../panel/panel_fragment"
import {
    createWindowFragment,    
    updateModelInch,    
    updateFragmentLines,
    updateWindow
} from "../window/functions_window";

export const setViewPortSize = (            
    renderData: RenderData,    
) =>{        
    renderData.viewport.valid_size = true;
    
    //Set the viewport width and height, according to scale
    const scale_size = getScaleViewPort(renderData);
    renderData.viewport.width = scale_size.width;
    renderData.viewport.height = scale_size.height;
    
    renderData.window = []; //Destroy any existing window
    renderData.active_fragment_id =-1; //Reset the active fragment if any

    //Create the first window fragment as the size of the viewport.
    createWindowFragment(renderData, 0, Number(renderData.viewport.width), 0, Number(renderData.viewport.height));

    renderData.render_frame = true;
}

//Handle a mouse click on the viewport
export const handleClick = (
    renderData: RenderData,
    setRenderData: React.Dispatch<React.SetStateAction<RenderData>>,    
    coord_x: number, 
    coord_y: number 
) =>{

    const window = renderData.window[0];
    if(renderData.window.length > 1 || window.panel.length >1){
        const render = getRenderDataCopy(renderData);  
        
        selectWindow(render, coord_x, coord_y); //Select a window or panel
        
        //Set state        
        setRenderData(render);    
    }
}

//Select a window based on mouse coordinates
const selectWindow = (    
    render: RenderData,    
    coord_x: number, 
    coord_y: number
) =>{
    //Iterate over all the window fragments
    render.window.forEach((window: WindowFragment) =>{        
        //If the coordinates are within the bounds of the fragment
        if(
            coord_x > window.model.line[Segment.LEFT].x1 &&
            coord_x < window.model.line[Segment.RIGHT].x1 &&
            coord_y > window.model.line[Segment.TOP].y1 &&
            coord_y < window.model.line[Segment.BOTTOM].y1
        ){
            let clicked_panel =-1;
            
            //Iterate through the panels to see if the coords are within the bounds of a panel
            window.panel.forEach((panel: PanelFragment) =>{                
                if(
                    coord_x > panel.model.line[Segment.LEFT].x1 &&
                    coord_x < panel.model.line[Segment.RIGHT].x1 &&
                    coord_y > panel.model.line[Segment.TOP].y1 &&
                    coord_y < panel.model.line[Segment.BOTTOM].y1                        
                ){
                    clicked_panel = panel.id;
                }
            })   
            
            //Catching if the click was on the frame border; may do something else with this later
            if(clicked_panel <0) clicked_panel = 0;
            
            //If the active fragment wasn't the window which was clicked on then select it
            if(render.active_fragment_id !== window.id){
                if(render.active_fragment_id >=0){ //If the current selected fragment isn't a null window, deselect its panel
                    render.window[render.active_fragment_id].active_panel_id =-1;
                }

                render.active_fragment_id = window.id;
                window.active_panel_id = clicked_panel;                
            }
            else{
                //If the active window and panel weren't clicked on, select the new one
                if(clicked_panel !== window.active_panel_id){
                    window.active_panel_id = clicked_panel;
                }
                else{ //Deselect the window and panel
                    window.active_panel_id =-1;
                    render.active_fragment_id =-1;
                }
            }
            
            render.render_frame = true;
        }        
    });
}

//Split a window fragment horizontally
export const SplitHorizontal = (    
    renderData: RenderData,    
) =>{
    
    if(renderData.active_fragment_id >=0){     
        
        const active_fragment = renderData.window[renderData.active_fragment_id] //Get a reference to the active window
        
        //Find the midpoint on the Y axis    
        const split_y_top = (active_fragment.model.line[Segment.TOP].y1 + active_fragment.model.line[Segment.BOTTOM].y1) /2;
        //Store the bottom of the active fragment
        const split_y_bottom = active_fragment.model.line[Segment.BOTTOM].y1;

        //Update the active fragment
        active_fragment.model.line[Segment.BOTTOM].y1 = split_y_top;
        active_fragment.model.line[Segment.BOTTOM].y2 = split_y_top;
        active_fragment.model.line[Segment.RIGHT].y2 = split_y_top;
        active_fragment.model.line[Segment.LEFT].y2 = split_y_top;

        updateModelInch(renderData, active_fragment);
        updateWindow(renderData, active_fragment);
        
        //Craete an active fragment below the selected window
        createWindowFragment(            
            renderData,
            renderData.window[active_fragment.id].model.line[Segment.LEFT].x1,
            renderData.window[active_fragment.id].model.line[Segment.RIGHT].x1,
            split_y_top,
            split_y_bottom            
        )
        
        renderData.render_frame = true;
    }
}   

//Split a window fragment vertically
export const SplitVertical = (    
    renderData: RenderData,    
) =>{
    if(renderData.active_fragment_id >=0){             
        const active_fragment = renderData.window[renderData.active_fragment_id] //Get a reference to the active window
        
        //Find the midpoint on the Y axis    
        const split_x_left = (active_fragment.model.line[Segment.LEFT].x1 + active_fragment.model.line[Segment.RIGHT].x1) /2;
        //Store the bottom of the active fragment
        const split_x_right = active_fragment.model.line[Segment.RIGHT].x1;

        //Update the active fragment
        active_fragment.model.line[Segment.RIGHT].x1 = split_x_left;
        active_fragment.model.line[Segment.RIGHT].x2 = split_x_left;        
        active_fragment.model.line[Segment.TOP].x2 = split_x_left;
        active_fragment.model.line[Segment.BOTTOM].x2 = split_x_left;
        
        updateModelInch(renderData, active_fragment);
        updateWindow(renderData, active_fragment);

        //Create an active fragment below the selected window
        createWindowFragment(            
            renderData,
            split_x_left,
            split_x_right,
            renderData.window[active_fragment.id].model.line[Segment.TOP].y1,
            renderData.window[active_fragment.id].model.line[Segment.BOTTOM].y1
        )        
        
        renderData.render_frame = true;
    }
}

//Adjust the top of a Fragment
export const changeTop = (    
    renderData: RenderData,    
    value: string) =>{      
    
    const invert_top = renderData.viewport.inch_height - Number(value);
    
    const pixel_top = getHeightPixel(invert_top, renderData);
    let active_fragment = renderData.window[renderData.active_fragment_id];

    updateFragmentLines(pixel_top, active_fragment.model.line[Segment.TOP], renderData);
    
    renderData.render_frame = true;
}

//Adjust the bottom of a Fragment
export const changeBottom = (    
    renderData: RenderData,    
    value: string) =>{
    
    const invert_bottom = renderData.viewport.inch_height - Number(value);

    const pixel_bottom = getHeightPixel(invert_bottom, renderData);
    let active_fragment = renderData.window[renderData.active_fragment_id];

    updateFragmentLines(pixel_bottom, active_fragment.model.line[Segment.BOTTOM], renderData);
    
    renderData.render_frame = true;
}

//Adjust the left side of a Fragment
export const changeLeft = (    
    renderData: RenderData,
    value: string) =>{

    const pixel_left = getWidthPixel(Number(value), renderData);
    let active_fragment = renderData.window[renderData.active_fragment_id];

    updateFragmentLines(pixel_left, active_fragment.model.line[Segment.LEFT], renderData);
    
    renderData.render_frame = true;
}

//Adjust the right side of a Fragment
export const changeRight = (    
    renderData: RenderData,
    value: string) =>{

    const pixel_right = getWidthPixel(Number(value), renderData);
    let active_fragment = renderData.window[renderData.active_fragment_id];

    updateFragmentLines(pixel_right, active_fragment.model.line[Segment.RIGHT], renderData);
    
    renderData.render_frame = true;
}

//Adjust the width of a fragment
export const changeWidth = (    
    renderData: RenderData,    
    value: string) =>{

    let active_fragment = renderData.window[renderData.active_fragment_id];
    
    const pixel_value = getWidthPixel(Number(value), renderData); //Inch value converted to pixel
    const pixel_width = getWidthPixel(active_fragment.model_inch.width, renderData); //Convert the width to pixels    

    //It borders the left, so only adjust the right
    if(active_fragment.model_inch.left === 0){
        updateFragmentLines(pixel_value, active_fragment.model.line[Segment.RIGHT], renderData);
    }
    //It borders the right, only adjust the left
    else if(active_fragment.model_inch.right === renderData.viewport.inch_width){
        const pixel_left = active_fragment.model.line[Segment.LEFT].x1 + (pixel_width - pixel_value);      
        updateFragmentLines(pixel_left, active_fragment.model.line[Segment.LEFT], renderData);
    }
    //It borders neither, so adjust both
    else{
        const half_value = (pixel_value - pixel_width)/2;
        const pixel_left = active_fragment.model.line[Segment.LEFT].x1 - half_value;
        const pixel_right = active_fragment.model.line[Segment.RIGHT].x1 + half_value;

        updateFragmentLines(pixel_left, active_fragment.model.line[Segment.LEFT], renderData);
        updateFragmentLines(pixel_right, active_fragment.model.line[Segment.RIGHT], renderData);
    }

    renderData.render_frame = true;
}

//Adjust the height of a fragment
export const changeHeight = (    
    renderData: RenderData,
    value: string
) =>{    
    let active_fragment = renderData.window[renderData.active_fragment_id];    

    const pixel_value = getHeightPixel(Number(value), renderData);
    const pixel_height = getHeightPixel(active_fragment.model_inch.height, renderData);

    //It borders the top
    if(active_fragment.model_inch.top === 0){
        updateFragmentLines(pixel_value, active_fragment.model.line[Segment.BOTTOM], renderData);
    }
    //It borders the bottom
    else if(active_fragment.model_inch.bottom === renderData.viewport.inch_height){
        const pixel_top = active_fragment.model.line[Segment.TOP].y1 + (pixel_height - pixel_value);
        updateFragmentLines(pixel_top, active_fragment.model.line[Segment.TOP], renderData);
    }
    //It borders right, so adjust both
    else{        
        const half_value = (pixel_value - pixel_height)/2;
        const pixel_top = active_fragment.model.line[Segment.TOP].y1 - half_value;
        const pixel_bottom = active_fragment.model.line[Segment.BOTTOM].y1 + half_value;

        updateFragmentLines(pixel_top, active_fragment.model.line[Segment.TOP], renderData);
        updateFragmentLines(pixel_bottom, active_fragment.model.line[Segment.BOTTOM], renderData);
    }

    renderData.render_frame = true;
}

//Clear viewport and reset it
export const clearViewport = (    
    renderData: RenderData,    
) =>{
    renderData.active_fragment_id = -1;
    renderData.window = []; //Destroy any existing window    
    
    //Create the first window fragment as the size of the viewport.
    createWindowFragment(renderData, 0, Number(renderData.viewport.width), 0, Number(renderData.viewport.height));

    renderData.render_frame = true;
}

//Set the color of the entire window frame
export const setFrameColor = (
    renderData: RenderData,    
    value: string
) =>{    
    renderData.all_window.color_frame = value;

    renderData.render_frame = true;
}

//Set the number of grids along the X axis
export const setGridX = (    
    renderData: RenderData,    
    value: number    
) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    panel.mask_grid.grid_x = value;
    renderData.render_frame = true;
}

//Set the number grids along the Y axis
export const setGridY = (    
    renderData: RenderData,    
    value: number
) =>{    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    panel.mask_grid.grid_y = value;
    renderData.render_frame = true;
}

//Convert pixel width to width in inches
export const getWidthInch = (pixel_width: number, renderData: RenderData): number =>{ 
    return (pixel_width / Number(renderData.viewport.width)) *  renderData.viewport.inch_width;
}
//Convert pixels in height to inches in height
export const getHeightInch = (pixel_height: number, renderData: RenderData): number =>{
    return (pixel_height / Number(renderData.viewport.height)) * renderData.viewport.inch_height;    
}
//Convert inches in width to pixels in width
export const getWidthPixel = (inch_width: number, renderData: RenderData): number =>{
    return (inch_width / renderData.viewport.inch_width) * Number(renderData.viewport.width);
}
//Convert height in inches to height in pixels
export const getHeightPixel = (inch_height: number, renderData: RenderData): number =>{
    return (inch_height / renderData.viewport.inch_height) * Number(renderData.viewport.height);
}

interface ScaleSize { //Object for scaling the width and height
    width: number,
    height: number    
}

const getScaleViewPort = (renderData: RenderData) =>{
    //Convert strings to numbers
    const width = renderData.viewport.inch_width
    const height = renderData.viewport.inch_height;
    //Init return object
    const scale_size: ScaleSize = {
        width: 0,
        height: 0
    }

    if(width > height){ //If width is larger, then scale based on width
        scale_size.width = renderData.viewport.max_width;

        //Divide the max viewport size by width and then scale the height based on that.
        const scale_value = renderData.viewport.max_width / width;
        scale_size.height = height * scale_value;

    }else{ //Scale based on height
        scale_size.height = renderData.viewport.max_height;

        //Divide the max viewport size by height and then scale the width based on that.        
        const scale_value = renderData.viewport.max_height / height;
        scale_size.width = width * scale_value;
    }

    //Round to the nearest integer
    scale_size.width = Math.round(scale_size.width);
    scale_size.height = Math.round(scale_size.height);

    return scale_size;
}