import ControlDropDown from "../controls/control_drop_down";
import Form from "react-bootstrap/Form";
import {RenderData} from "../render/render_components/render_data/render_data";
import {useState, useEffect} from "react";
import {ControlRadio} from "../controls/control_radio";
import {ConstrainValues} from "../constraints/constrain_value";

interface GlassProps{    
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;    
    renderData: RenderData;
    constrainValues: ConstrainValues;    
}

const DROP_DOWN_MAX_WIDTH = "250px";

const GlassForm = ({id_item, DataHandler, renderData, constrainValues}: GlassProps) =>{
    
    const [selectOuter, setSelectOuter] = useState<number>(-1);
    const [selectInner, setSelectInner] = useState<number>(-1);
    const [treatmentInner, setTreatmentInner] = useState<number>(-1);
    const [treatmentOuter, setTreatmentOuter] = useState<number>(-1);

    //When render data changes, set selected glass id's
    useEffect(() =>{
        if(renderData.active_fragment_id >=0){                       
            const window = renderData.window[renderData.active_fragment_id];
            const panel = window.panel[window.active_panel_id];

            const record_annealed = renderData.config_values.treatment.get("Annealed");
            const record_tempered = renderData.config_values.treatment.get("Tempered");
            
            if(panel.data.glass.outer.tempered){
                setTreatmentOuter(record_tempered!.id);
            }
            else{
                setTreatmentOuter(record_annealed!.id);
            }
            if(panel.data.glass.inner.tempered){
                setTreatmentInner(record_tempered!.id);
            }else{
                setTreatmentInner(record_annealed!.id);
            }

            setSelectOuter(panel.data.glass.outer.id);
            setSelectInner(panel.data.glass.inner.id);            
        }
        else{
            setSelectOuter(-1);
            setSelectInner(-1);
            setTreatmentOuter(-1);
            setTreatmentInner(-1);
        }
    }, [renderData])

    return(
        <Form>            
            <div className="text-center">
                <h4>Outside Window Glass</h4>
            </div>
            <div className="mb-3" style={{maxWidth: DROP_DOWN_MAX_WIDTH}}>                
                <ControlDropDown                                        
                    id_item={id_item}
                    id_object="treatment_outer"
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.treatment}
                    id_select={treatmentOuter}
                />
            </div>    
            <ControlRadio
                id_item={id_item}
                group_name="group_outer_glass"
                DataHandler={DataHandler}
                parameter={renderData.config_values.glass}
                id_select={selectOuter}
                constrain={constrainValues.glass.parameter}
                
            />          
            <div className="text-center mt-3">
                <h4>Inside Window Glass</h4>
            </div>
            <div className="mb-3" style={{maxWidth: DROP_DOWN_MAX_WIDTH}}>                
                <ControlDropDown 
                    id_item={id_item}
                    id_object="treatment_inner"
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.treatment}
                    id_select={treatmentInner}                    
                />
            </div>            
            <ControlRadio
                id_item={id_item}
                group_name="group_inner_glass"
                DataHandler={DataHandler}
                parameter={renderData.config_values.glass}
                id_select={selectInner}
                constrain={constrainValues.glass.parameter}
            />                                 
        </Form>  
    );    
}

export default GlassForm;