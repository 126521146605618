import {RenderData} from "../../render/render_components/render_data/render_data";
import {OrderRequest} from "./order_request";
import {initOrderDetail, initDetailWindow, initDetailPanel, OrderDetail, DetailPanel} from "./order_detail";
import {Segment} from "../../render/render_components/render_data/enum_render";
import {OpenDirection, OpenDirectionName} from "../../render/render_components/render_data/enum_render";

//Pack the order detail from the render data object and the order request price
export const packOrderDetail = (renderData: RenderData, orderRequest: OrderRequest): OrderDetail =>{
    const orderDetail = initOrderDetail();
    
    //Set the price
    orderDetail.price = orderRequest.price;

    //Set the values which apply to the net window
    orderDetail.product_line = renderData.data.product_line.value;
    orderDetail.frame_type = renderData.data.frame_type.value;
    orderDetail.frame_color = renderData.data.frame_color.value;
    //If it's a callout
    if(renderData.data.is_callout){
        orderDetail.is_callout = true;
        orderDetail.callout = renderData.data.callout;
    }
    else{ //It's an overall net
        orderDetail.width = renderData.viewport.inch_width;
        orderDetail.height = renderData.viewport.inch_height;
    }
    
    //Get a sorted list of indices, according to window position (top - bottom, left - right)
    const index_list = sortWindows(renderData);

    //Iterate through all the windows in the render data
    //renderData.window.forEach(window =>{
    index_list.forEach(index =>{
        const window = renderData.window[index];
        
        const order_window = initDetailWindow();

        //Add window information to the order window
        order_window.width = window.model_inch.width;
        order_window.height = window.model_inch.height;
        order_window.second_height = window.model_inch.second_height;
        order_window.product_type = window.data.product_type.value;        

        //Iterate through all the panels in the window
        window.panel.forEach(panel =>{
            const order_panel = initDetailPanel();
            
            //Set the values of the panel
            order_panel.width = panel.model_inch.display_width;
            order_panel.height = panel.model_inch.display_height;
            
            //Set the open direction if one exists
            if(panel.mask_open.direction !== OpenDirection.NONE){
                order_panel.open_direction = OpenDirectionName[panel.mask_open.direction];
            }
            
            //Set the glass values
            order_panel.outer_glass.glass = panel.data.glass.outer.value;
            order_panel.outer_glass.tempered = panel.data.glass.outer.tempered;
            order_panel.inner_glass.glass = panel.data.glass.inner.value;
            order_panel.inner_glass.tempered = panel.data.glass.inner.tempered;

            //Set the grid values
            order_panel.grid.has_grid = panel.data.grid.has_grid;

            //Iterate through all the options in the panel fragment
            panel.data.option.forEach(option =>{
                //Get the option value string and add it to the panel option array
                order_panel.option[order_panel.option.length] = option.value;
            })

            //Set the order panel into the order window
            order_window.panel[order_window.panel.length] = order_panel;
        })

        //Set the order window into the array
        orderDetail.window[orderDetail.window.length] = order_window;
    })

    //Create a string array of the order details
    const order_string: string[] = getOrderString(orderDetail);
    orderDetail.order_string = order_string;

    return orderDetail;

}

//Sort the windows by top to bottom and left to right and return a list of indices in order
const sortWindows = (renderData: RenderData): number[] =>{

    let window_list: number[] = [];
    let height_list: number[] = [];
    let height_map: Map<number, number[]> = new Map();
    //Get a list of all the top values of the windows
    renderData.window.forEach(window =>{
        const top = window.model.line[Segment.TOP].y1;

        //If the top value isn't already in the list
        if(!height_list.includes(top)){
            height_list[height_list.length] = top;

            let index_list: number[] = [];
            index_list[0] = window.id;
            height_map.set(top, index_list);
        }
        else{
            const index_list = height_map.get(top)!;
            index_list[index_list.length] = window.id;
        }
    })

    //Sort the list of heights
    height_list.sort();    

    //Iterate through sorted heights, put their lefts into a list, sort and then add the index to the window list    
    height_list.forEach(height =>{       
        
        const index_list = height_map.get(height)!;
        
        //List of left values
        let left_list: number[] = [];
        
        //Iterate through all the indexes stored in the list
        index_list.forEach(index =>{
            const window = renderData.window[index];

            left_list[left_list.length] = window.model.line[Segment.LEFT].x1;
        })

        left_list.sort(); //Sort the left list

        //Iterate through the 
        left_list.forEach(left =>{
            
            //Iterate through windows in the index list to find the left value it matches, then add it to the overall list
            index_list.forEach(index =>{
                const window = renderData.window[index];

                if(window.model.line[Segment.LEFT].x1 === left){
                    window_list[window_list.length] = window.id;
                }
            })
        })        
    })

    return window_list;
}

//Get an output of order detail in an array format
const getOrderString = (orderDetail: OrderDetail): string[] =>{
    const list: string[] = [];
    
    //If it's a callout
    if(orderDetail.is_callout){
        addString(list, "Callout: " + orderDetail.callout);
    }
    else{
        addString(list, "Width: " + orderDetail.width);
        addString(list, "Height: " + orderDetail.height);
    }

    //Add base values
    addString(list, "Product Line: " + orderDetail.product_line);
    addString(list, "Frame Type: " + orderDetail.frame_type);
    addString(list, "Frame Color: " + orderDetail.frame_color);

    let window_count =1;
    //Iterate through the windows    
    orderDetail.window.forEach(window =>{               
        
        if(orderDetail.window.length >1){
            addString(list, "Window (" + window_count + ")" )
            addString(list, "Width: " + window.width);
            addString(list, "Height: " + window.height);

            window_count ++;
        }
        if(window.second_height >0){
            addString(list, "Second Height: " + window.second_height);            
        }

        addString(list, "Product Type: " + window.product_type);
                
        //If it has multiple panels
        if(window.panel.length >1){
            let panel_count =1;
            
            //Iterate through all the panels
            window.panel.forEach(panel =>{
                addString(list, "Panel (" + panel_count + ")");
                addString(list, "Width: " + panel.width);
                addString(list, "Height: " + panel.height);                
                //Open Direction
                if(panel.open_direction !== ""){
                    addString(list, "Open Direction: " + panel.open_direction);
                }
                addGlass(panel, list);
                
                //******** Grid stuff for the future
                if(panel.grid.has_grid){
                    addString(list, "Has Grid");                    
                }
                if(panel.has_screen){
                    addString(list, "With Screen");
                }
                
                addOption(panel, list);

                panel_count ++;
            })
        }
        else{
            const panel = window.panel[0];
            addGlass(panel, list);
            
            //******** Grid stuff for the future
            if(panel.grid.has_grid){
                addString(list, "Has Grid");
            }

            addOption(panel, list);
        }
    })    

    return list;
}

//Little helper function to add in some glass to the string
const addGlass = (panel: DetailPanel, list: string[]) =>{
    let outer_glass = "Outer Glass: ";
    if(panel.outer_glass.tempered){
        outer_glass += "Tempered ";
    }
    outer_glass += panel.outer_glass.glass;

    let inner_glass = "Inner Glass: ";
    if(panel.inner_glass.tempered){
        inner_glass += "Tempered ";
    }
    inner_glass += panel.inner_glass.glass;

    addString(list, outer_glass);
    addString(list, inner_glass);
}

const addOption = (panel: DetailPanel, list: string[]) =>{
    //If there are any options in the panel
    if(panel.option.length >0){
        let option_string = "Options: ";
            
        //Iterate through all the option values, except the last one in the array
        for(let counter=0; counter < panel.option.length -1; counter++){
            option_string += panel.option[counter] + ", ";
        }        
        
        //Add the last option
        option_string += panel.option[panel.option.length -1];    

        addString(list, option_string);
    }    
}

//Just a shorthand way for handling the addition of strings to the array
const addString = (list: string[], value: string) =>{    
    list[list.length] = value;
}


