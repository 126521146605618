import Form from "react-bootstrap/Form";
import {useState, useEffect} from "react";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ControlRadio} from "../controls/control_radio";
import {ConstrainValues} from "../constraints/constrain_value";

interface WindowGridProps{ //Props for the Window Grid object    
    id_item: string;    
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, id_key: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;    
}

const WindowGridForm = ({id_item, DataHandler, renderData, constrainValues}: WindowGridProps) => {

    const INPUT_MAX_WIDTH = "150px"; //Max size of the input text boxes

    //Use States for dvl width and dvl height
    const [dvl_width, setWidth] = useState<string>("");
    const [dvl_height, setHeight] = useState<string>("");
    const [selected, setSelected] = useState<number>(-1);

    //Handle the text boxes, when values are changed/inputted
    const handleTextChange = (input_id: string, input_value: string) =>{
        const value_number = Number(input_value);
        if(!isNaN(value_number)){ //Value must be a number or empty            
            if(value_number >=0){ //Value must be 0 or greater
                let new_width = dvl_width;
                let new_height = dvl_height;
                            
                if(input_id === "dvl_width"){ //If the input is for dvl width            
                    new_width = input_value;
                    setWidth(new_width);
                }
                else{ //Input is for dvl height            
                    new_height = input_value;
                    setHeight(new_height);
                }
                
                //Send the data off to be handled with the data handler function
                DataHandler(id_item, "", input_id, input_value, -1);       
            }

            //Update the text boxes with the input value; allow for blanks
            if(input_id === "dvl_width"){
                setWidth(input_value);
            }
            else{
                setHeight(input_value);
            }
        }   
    }

    //Update the controls when renderData changes
    useEffect(() =>{        
        if(renderData.active_fragment_id >=0){ //If a fragment is selected
            const window = renderData.window[renderData.active_fragment_id];               
            const panel = window.panel[window.active_panel_id];

            setWidth(String(panel.mask_grid.grid_x));
            setHeight(String(panel.mask_grid.grid_y));

            setSelected(panel.data.grid.grid_type.id);
        }
        else{            
            setWidth("");
            setHeight("");            
            setSelected(-1);
        }        
    }, [renderData])
    
    return(
        <Form>
            <div key="grid_type" className="mb-3">
                <ControlRadio
                    id_item={id_item}                    
                    group_name="group_grid_type"                
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.grid_type}
                    id_select={selected}
                    constrain={constrainValues.grid_type.parameter}                    
                />            
            </div>
            <Form.Group className="mb-3">
                <Form.Label>DVL Width</Form.Label>
                <Form.Control 
                    id="dvl_width"
                    type="text" 
                    placeholder="Enter DVL Width" 
                    style={{width: INPUT_MAX_WIDTH}}
                    value={dvl_width}
                    onChange={(event) => handleTextChange(event.target.id, event.target.value)} 
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>DVL Height</Form.Label>
                <Form.Control 
                    id="dvl_height"
                    type="text" 
                    placeholder="Enter DVL Height" 
                    style={{width: INPUT_MAX_WIDTH}} 
                    value={dvl_height}
                    onChange={(event) => handleTextChange(event.target.id, event.target.value)} 
                />
            </Form.Group>
        </Form>
    );
}

export default WindowGridForm;