
//Interface for grid details
interface DetailGrid{
    has_grid: boolean
}
//Initialize the grid details
export const initDetailGrid =() =>({
    has_grid: false
})

//Interface for glass detail
interface DetailGlass{
    tempered: boolean,
    glass: string
}

//Init glass details for the paneel
export const initDetailGlass = () =>({
    tempered: false,
    glass: ""
})

//Interface for panel details
export interface DetailPanel{
    width: number,
    height: number,
    open_direction: string,
    has_screen: boolean,    
    inner_glass: DetailGlass,
    outer_glass: DetailGlass,
    grid: DetailGrid
    option: string[]
}

//Init details for a panel
export const initDetailPanel =(): DetailPanel =>({
    width: 0,
    height: 0,
    open_direction: "",
    has_screen: false,
    inner_glass: initDetailGlass(),
    outer_glass: initDetailGlass(),
    grid: initDetailGrid(),
    option: []
})

//Details for a window
export interface DetailWindow{
    width: number,
    height: number,
    second_height: number,
    panel: DetailPanel[],
    product_type: string
}
//Init details for a window
export const initDetailWindow =(): DetailWindow =>({
    width: 0,
    height: 0,
    second_height: 0,
    panel: [],
    product_type: ""
})

//Iterface for details of an order
export interface OrderDetail{
    is_callout: boolean,
    callout: number,
    width: number,
    height: number,
    product_line: string,
    frame_type: string,
    frame_color: string,
    window: DetailWindow[],
    price: number,
    order_string: string[]
}

//Initialize the details of an order
export const initOrderDetail =(): OrderDetail =>({
    is_callout: false,
    callout: 0,
    width: 0,
    height: 0,
    product_line: "",
    frame_type: "",
    frame_color: "",
    window: [],
    price: 0,
    order_string: []
})
