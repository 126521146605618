import {useState, useEffect, Fragment, ReactNode} from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {OrderList} from "../data/order/order_list";
import {OrderDetail} from "../data/order/order_detail";
import { getOrderListCopy } from "../data/copy_data";

interface Props{
    orderList: OrderList,
    setOrderList: React.Dispatch<React.SetStateAction<OrderList>>
}

interface AccordionItem{
    key: number
    header: ReactNode
    body: () => ReactNode;
}

const initAccordionItem = (): AccordionItem =>({
    key: 0,
    header: "",
    body: () => "Default"
})

//Add an order to the order list
export const OrderListControl = ({
    orderList,
    setOrderList
}: Props) =>{
    
    const [items, setItems] = useState<AccordionItem[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);

    //Handle the selection of an accordion item
    const handleClick = (key: number) =>{
        const list = getOrderListCopy(orderList);
        list.selected_index = key;
        setOrderList(list);
    }    

    //Handle the remove button click
    const handleRemoveButton = (key: number) =>{
        const list = getOrderListCopy(orderList);
        list.list.delete(key);        
        list.selected_index =-1;
        setOrderList(list);
    }

    //Monitor for when order list is updated
    useEffect (() =>{
        const accordionItems: AccordionItem[] = [];
        
        let counter=0;
        let total_price =0;
        //Iterate through all the entries in the order list
        orderList.list.forEach(entry =>{
            const currentItem = initAccordionItem();
            
            let header = "";            
            
            //Add the window size and product type or window count to the header
            if(entry.orderDetail.is_callout){
                header = String(entry.orderDetail.callout);
            }
            else{
                header = entry.orderDetail.width + " x " + entry.orderDetail.height;
            }
            
            //If it's one window
            if(entry.orderDetail.window.length === 1){
                header += " " + entry.orderDetail.window[0].product_type;
            }
            else{ //It's multiple windows
                header += " (" + entry.orderDetail.window.length + ") Windows";
            }
            
            currentItem.key = entry.index;
            //Create a react node to allow for aligning the price to the right on the header
            currentItem.header = (
                <div className="d-flex justify-content-between w-100">
                    <span>{header}</span>
                    <span className="justify-content-end me-2">${entry.orderDetail.price.toFixed(2)}</span>
                </div>
            );            
            
            //Set the accordion item to a function
            currentItem.body = () => accordionBody(entry.orderDetail, currentItem.key, handleRemoveButton);
            accordionItems[counter] = currentItem;

            //Calculate the total price and prevent rounding errors
            total_price += entry.orderDetail.price;
            const deci = 100;
            total_price = Math.round(total_price * deci) / deci;

            counter++;            
        })
        
        setTotalPrice(total_price);
        setItems(accordionItems);        
    }, [orderList])


    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">                
                <h5>Order List</h5>
            </div>
            {accordionOrder(items, handleClick)}
            <div className="d-flex justify-content-end align-items-center mt-2">                    
                <Form.Label className="mt-2 me-2">Total Price</Form.Label>                
                <Form.Control 
                    type="text"
                    value={totalPrice.toFixed(2)}
                    style={{width: "120px"}}                    
                    readOnly
                />
            </div>
        </Fragment>
    );
}

//Return an accordion for orders
const accordionOrder = (
    items: AccordionItem[], 
    handleClick: (key: number) => void
) =>{
    return (
        <Fragment>              
            <Accordion>
                {items.map(item => (
                    <Accordion.Item eventKey={item.key.toString()} key={item.key}>                        
                        <Accordion.Header
                            onClick={() => handleClick(item.key)}
                        >
                            {item.header}
                        </Accordion.Header>
                        <Accordion.Body>{item.body()}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </Fragment>
    );
};

//Return the accordion body of an accordion item
const accordionBody = (
    orderDetail: OrderDetail, 
    key: number, 
    handleRemoveButton: (key: number) =>void
) =>{
    
    const order_string: string[] = orderDetail.order_string;

    //Handle copying the button to the clipboard
    const handleCopyButton = () =>{
        
        navigator.clipboard.writeText(order_string.join("\n"))
        .then(() =>{
            //********* Can do something here if we need
        })
        .catch(error =>{
            console.error("Failed to copy text: ", error);
        })
    }

    return(
        <Fragment>
            <div className="d-flex justify-content-end align-items-end">                
                <Button
                    onClick={ () =>handleRemoveButton(key)}
                >
                    Remove
                </Button>
                <Button
                    onClick={handleCopyButton}
                    style={{marginLeft: '0.5rem'}}
                >
                    Copy To Clipboard
                </Button>
            </div>
            {order_string.map((line, index) =>(
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ))}            
        </Fragment>
    )
}







