import {RenderData} from "../../render/render_components/render_data/render_data";

const FrameType = (object_value: string, key_id: number, renderData: RenderData) =>{
    
    if(key_id === -1 && renderData.data.frame_type.id >0){
        renderData.data.frame_type.id =0;
        renderData.data.frame_type.value ="";
    }
    else{
        renderData.data.frame_type.value = object_value;
        renderData.data.frame_type.id = key_id;
    }    
}

export default FrameType;