import {WindowFragment} from "../window/window_fragment";
import {FrameColor} from "./enum_render";
import {ConfigValues, initConfigValues} from "./config_values";
import {ErrorPoint, initErrorPoint} from "./error_data";

//Viewport values
interface ViewPort{
    width: number  //Width of the viewport
    height: number  //Height of the viewport
    valid_size: boolean //If the width and height are valid
    max_width: number //Max width of the viewport
    max_height: number //Max height of the viewport
    //border_size: string //The thickness of the border
    select_color: string //The color when a fragment is selected
    select_outline_color: string //Secondary color for color selection; the outline of the select
    select_outline_width: number //Width of the select outline border
    context: CanvasRenderingContext2D | null //Context hook to the viewport canvas for drawing    
    inch_width: number //The width of the viewport in inches
    inch_height: number //The height of the viewport in inches
    line_width: number //The width of the line border
    line_style: string //The style of the line border
}

interface AllWindow{
    color_frame: string
    layout: string        
}

export interface DataPoint{
    value: string
    id: number
    default_value: string
}

interface AllData{
    is_callout: boolean
    callout: number    
    frame_color: DataPoint
    frame_type: DataPoint
    product_line: DataPoint    
}

export interface RenderData{
    viewport: ViewPort; //Viewport ie: the canvas
    window: WindowFragment[]; //Array containing all the window fragments    
    active_fragment_id: number; //The id for the active window fragment if any -1 of none
    render_frame: boolean; //A boolean value stating if a frame needs to be rendered. (Note not window frame)
    all_window: AllWindow; //Render values which apply to all window fragments
    config_values: ConfigValues; //All the selectable values which are used in the configurator
    data: AllData; //Data associated with this window
    error: ErrorPoint;    
}

export const initDataPoint = (default_value: string ):DataPoint =>({
    value: "",
    id: 0,
    default_value: default_value
})

export const initRenderData = ():RenderData =>({
    viewport:{
        width: 0, 
        height: 0, 
        valid_size: false,
        //Seems to look better when we scale based on the window height alone.
        //Width using the height screen height is intentional.
        //max_width: window.innerWidth * 0.50,
        max_width: window.innerHeight * 0.70,
        max_height: window.innerHeight * 0.70,
        //border_size: "2px solid black",        
        select_color: "rgba(10, 180, 75, 0.3)",
        select_outline_color: "rgba(10, 180, 75, 1)",
        select_outline_width: 4,
        context: null,
        inch_width: 0,
        inch_height: 0,
        line_width: 2,
        line_style: "black"                
    },
    window: [],
    active_fragment_id: -1,
    render_frame: false,
    all_window:{
        color_frame: FrameColor.DEFAULT,
        layout: ""        
    },
    config_values: initConfigValues(),
    data: {
        is_callout: false,
        callout: -1,
        frame_color: initDataPoint(""),
        frame_type: initDataPoint(""),
        product_line: initDataPoint(""),        
    },
    error: initErrorPoint()
});

//Reset the render data, but hang on to important information
export const resetRenderData = (renderData: RenderData) =>{
    const viewport = renderData.viewport;
    viewport.width = 0;
    viewport.height =0;
    viewport.valid_size = false;
    viewport.inch_width =0;
    viewport.inch_height =0;
    viewport.context = null;
    
    renderData.window = [];
    renderData.active_fragment_id =-1;
    renderData.render_frame = false;

    renderData.all_window.color_frame = FrameColor.DEFAULT;
    
    const data = renderData.data;
    data.is_callout =false;
    data.callout =-1;
    data.frame_color = initDataPoint("");
    data.frame_type = initDataPoint("");
    data.product_line = initDataPoint("");

    renderData.error = initErrorPoint();
}

