import {RenderData} from "../../render/render_components/render_data/render_data";

const Glass = (id_object: string, object_value: string, key_id: number, renderData: RenderData) =>{
    
    const fragment = renderData.window[renderData.active_fragment_id];
    const panel = fragment.panel[fragment.active_panel_id];
    
    //Outer Treatment
    if(id_object === "treatment_outer"){
        if(object_value === "Tempered"){            
            panel.data.glass.outer.tempered = true;
        }
        else{            
            panel.data.glass.outer.tempered = false;
        }
    }
    //Inner Treatment
    if(id_object === "treatment_inner"){
        if(object_value === "Tempered"){
            panel.data.glass.inner.tempered = true;
        }
        else{            
            panel.data.glass.inner.tempered = false;
        }
    }
    //Outer glass
    if(id_object === "group_outer_glass"){
        panel.data.glass.outer.value = object_value;
        panel.data.glass.outer.id = key_id;
    }
    //Inner glass 
    if(id_object === "group_inner_glass"){
        panel.data.glass.inner.value = object_value;
        panel.data.glass.inner.id = key_id
    }        
}

export default Glass;