import {Fragment} from "react";
import ToggleButtons from "../controls/control_toggle_buttons";
import Button from 'react-bootstrap/Button';

const ID_HEADER_BUTTON = "header_button";

interface Props{
    DataHandler: (
        id_item: string, 
        form_group: string,
        form_object: string, 
        object_value: string,
        key_id: number,        
    ) => void;
}

export const HeaderButtons = ({DataHandler}: Props) =>{
    const clear_button = "Clear";

    //Clear the configurator when the clear button is clicked
    const handleClearClick = () =>{
        DataHandler(ID_HEADER_BUTTON, "", clear_button, "", -1);
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-5" >
                        <div className="d-flex mb-2 mt-2">
                            <ToggleButtons 
                                buttons={["Window", "Patio Door", "XOP", "Stack/Mull"]}
                                id_item={ID_HEADER_BUTTON}
                                DataHandler={DataHandler}
                            />
                            <div className="ms-auto d-flex justify-content-end align-items-center">
                                <Button
                                    onClick={handleClearClick}
                                >
                                    {clear_button}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            
        </Fragment>
    )
}