import {ADDRESS_SERVER} from "../../../settings";

export interface Callout{
    width: number,
    height: number
}

export const initCallout = ():Callout =>({
    width: 0,
    height: 0
})

//Get a callout net size from the server
export const getCallout = async(callout_size: number) =>{
    try {       

        const request_data = JSON.stringify({callout_size});

        const response = await fetch(ADDRESS_SERVER + "callout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: request_data
        });

        const jsonData:Callout = await response.json();        
        return jsonData;
        
    } catch (error:any) {
        console.log(error.message);
    }
}

