import {setGridX, setGridY} from "../../render/render_components/viewport/functions_viewport";
import {RenderData} from "../../render/render_components/render_data/render_data";

const WindowGrid = (
    id_object: string, 
    form_object: string, 
    object_value: string, 
    key_id: number,     
    renderData: RenderData,    
) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    //Grid Tpye
    if(id_object === "group_grid_type"){                        
        panel.data.grid.grid_type.value = object_value;
        panel.data.grid.grid_type.id = key_id;        
    }

    //DVLs
    if(form_object === "dvl_width"){
        if(object_value){                    
            panel.data.grid.dvl_width = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridX(renderData, Number(object_value));
            }                                        
        }
        else{
            panel.data.grid.dvl_width = 0;       
        }
    }
    if(form_object === "dvl_height"){
        if(object_value){
            panel.data.grid.dvl_height = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridY(renderData, Number(object_value));
            }                                     
        }
        else{
            panel.data.grid.dvl_height = 0;
        }
    }
    
    //If dvl_width and dvl_height have a value, then it has a grid
    if(panel.data.grid.dvl_width >0 &&
        panel.data.grid.dvl_height >0){
        panel.data.grid.has_grid = true;
    }
    else{ //It does not have a grid
        panel.data.grid.has_grid = false;
    }
}

export default WindowGrid;