//Important: This file needs to be maintained and identical between the server and the client

//Order grid object
interface OrderGrid{
    has_grid: boolean,
    grid_type: number,
    two_tone: boolean
}

//Init the order grid
const initOrderGrid = (): OrderGrid =>({
    has_grid: false,
    grid_type: -1,
    two_tone: false
})

//Order glass object
interface OrderGlass{
    tempered: boolean
    glass: number
}

//Init the order glass
const initOrderGlass = (): OrderGlass =>({
    tempered: false,
    glass: -1
})

//Order Panel object
export interface OrderPanel{
    glass_outside: OrderGlass,
    glass_inside: OrderGlass,
    grid: OrderGrid,
    has_screen: boolean,
    option: number[]
}

//Init the order panel
export const initOrderPanel = (): OrderPanel =>({
    glass_outside: initOrderGlass(),
    glass_inside: initOrderGlass(),
    grid: initOrderGrid(),
    has_screen: false,
    option: []
})

//Entire Order Request interface
export interface OrderRequest{    
    product_line: number,
    frame_type: number,
    frame_color: number,
    window: OrderWindow[],
    price: number
}

//Order request data for a single window
export interface OrderWindow{
    width: number,
    height: number,
    is_callout: boolean,
    callout: number,
    product_type: number,    
    panel: OrderPanel[]
}

//Init an order request
export const initOrderRequest = (): OrderRequest =>({    
    product_line: -1,
    frame_type: -1,
    frame_color: -1,
    window: [],
    price: 0
})

//Init an order window
export const initOrderWindow = (): OrderWindow =>({
    width: 0,
    height: 0,
    is_callout: false,
    callout: 0,
    product_type: -1,        
    panel: []
})
