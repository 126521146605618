import {setViewPortSize} from "../../render/render_components/viewport/functions_viewport";
import {RenderData} from "../../render/render_components/render_data/render_data";
import {getCallout} from "../get_callout";
import {ConstrainValues} from "../../constraints/constrain_value";
import {constrainProductLine, constrainOverallSize} from "../../constraints/functions_constrain";
import {createTwoPanel, setPanelOpen} from "../../render/render_components/panel/functions_panel";
import {PanelType, OpenDirection} from "../../render/render_components/render_data/enum_render";


const WindowSize = async (
    form_object: string,
    object_value: string,     
    renderData: RenderData,
    constrainValues: ConstrainValues
) =>{  
    
    switch(form_object){
        case "callout":
            renderData.data.is_callout = true;
            //This is for checking patio doors; they have different constraints based on callout or net
            if(renderData.data.product_line.id >=0){
                constrainProductLine(renderData.data.product_line.value, constrainValues, renderData);
            }
        break;
        case "special":
            renderData.data.is_callout = false;
            //This is for checking patio doors; they have different constraints based on callout or net
            if(renderData.data.product_line.id >=0){
                constrainProductLine(renderData.data.product_line.value, constrainValues, renderData);
            }
        break;
        case "size_callout":            
            //Get the callout width and height from the server and then set it accordingly            
            renderData.data.callout = Number(object_value);            
            const callout = await getCallout(Number(object_value));            
            if(callout){
                if(callout.width !== 0 && callout.height !== 0){    
                    renderData.viewport.inch_width = callout.width;
                    renderData.viewport.inch_height = callout.height;
                }
            }
        break;
        case "size_width":            
            renderData.viewport.inch_width = Number(object_value);
        break;
        case "size_height":
            renderData.viewport.inch_height = Number(object_value);
        break;
    }
    
    if(renderData.viewport.inch_width >0 && renderData.viewport.inch_height >0){        
        setViewPortSize(renderData);

        //Set the active window and panel to the window that was just created        
        renderData.active_fragment_id =0;
        const window = renderData.window[0];
        window.active_panel_id =0;
        
        //For patio doors, change the inital panel to OX instead of PW
        if(renderData.all_window.layout === "Patio Door"){
            const record = renderData.config_values.product_type.get("OX")!;
            window.data.product_type.id = record.id;
            window.data.product_type.value = record.description;
            
            createTwoPanel(renderData, window);            
            setPanelOpen(window, PanelType.RIGHT_OF_TWO, OpenDirection.LEFT);            
        }
        
        constrainOverallSize(renderData, constrainValues);
    }
    
}

export default WindowSize