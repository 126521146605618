import {RenderData} from "../../render/render_components/render_data/render_data";
import {OrderRequest, initOrderWindow, initOrderPanel} from "./order_request";
import {ADDRESS_SERVER} from "../../../../settings";

//Compile the order request object from the renderData object
export const packOrderRequest = (renderData: RenderData, order: OrderRequest): OrderRequest =>{
    
    //Overall window parameters
    order.product_line = renderData.data.product_line.id;
    order.frame_type = renderData.data.frame_type.id;
    order.frame_color = renderData.data.frame_color.id;

    //Iterate through all the windows in the render data object    
    renderData.window.forEach(window =>{        

        const order_window = initOrderWindow();
        
        //Set the product type id        
        order_window.product_type = window.data.product_type.id;
        
        //If it's a callout, use the callout values; not applicable to multi-window
        if(renderData.data.is_callout){
            order_window.is_callout = true;
            order_window.callout = renderData.data.callout;
        }
        else{ //Use the size of the individual window
            order_window.width = window.model_inch.width;
            order_window.height = window.model_inch.height;
        }

        //Iterate throguh all the panels
        window.panel.forEach(panel =>{
            const order_panel = initOrderPanel();

            //Set the inner and outer glass values
            order_panel.glass_outside.glass = panel.data.glass.outer.id;
            order_panel.glass_outside.tempered = panel.data.glass.outer.tempered;
            order_panel.glass_inside.glass = panel.data.glass.inner.id;
            order_panel.glass_inside.tempered = panel.data.glass.inner.tempered;

            //Set has screen value
            order_panel.has_screen = panel.data.has_screen;
            
            //If it has a grid
            if(panel.data.grid.has_grid){
                order_panel.grid.has_grid = true;

                //Set grid type
                order_panel.grid.grid_type = panel.data.grid.grid_type.id;
                
                //Get the frame color data object
                const frame_color = renderData.config_values.frame_color.get(renderData.data.frame_color.value);
                //If it's black capstock, it's considered a two-tone grid
                if(frame_color!.description === "Black Capstock"){
                    order_panel.grid.two_tone = true;
                }
            }

            //Iterate through all the options for the panel
            panel.data.option.forEach(option =>{
                order_panel.option[order_panel.option.length] = option.id;
            })

            //Set the order panel into the order window object
            order_window.panel[order_window.panel.length] = order_panel;
        })

        //Set the order window into the order object
        order.window[order.window.length] = order_window;
    })  

    return order;
}

//Get the order price for an order request
export const setOrderPrice = (
    setOrderRequest: React.Dispatch<React.SetStateAction<OrderRequest>>,
    order: OrderRequest
) =>{    

    const getPrice = async() =>{
        try {
            const request_data = JSON.stringify(order);

            //Send the order data to the server, get a corresponding price value in return
            const response = await fetch(ADDRESS_SERVER + "order", {
                method: 'Post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: request_data
            });

            //Get the result from the server and set it into the price
            const jsonData:number = await response.json();            
            order.price = jsonData;
            
            setOrderRequest(order);

        } catch (error: any) {
            console.log(error.message);
        }    
    }

    //If it's a valid order, try to get a price
    if(isValidOrder(order)) getPrice();
}

//Determine if an order should be sent off to the server to try and pull a price
const isValidOrder = (order: OrderRequest): boolean =>{
    let valid = true;
    
    if(order.product_line <1){
        valid = false;
    }
    if(order.frame_type <1){
        valid = false;
    }
    if(order.frame_color <1){
        valid = false;
    }
    if(order.window.length <1){
        valid = false;
    }
    else{
        //Validate the window
        const window = order.window[0];
        if(window.is_callout){
            if(window.callout <=0){
                valid = false;
            }
        }
        else{
            if(window.width <=0){
                valid = false;
            }
            if(window.height <=0){
                valid = false;
            }
        }     
    }   

    return valid;
}