import {RenderData} from "../render/render_components/render_data/render_data";
import {revertToPW} from "../render/render_components/window/functions_window";
import {WindowFragment} from "../render/render_components/window/window_fragment";
import {Segment} from "../render/render_components/render_data/enum_render";
import {validatePosts} from "./functions_validate_posts";
import {initErrorPoint} from "../render/render_components/render_data/error_data";
import {limitSide} from "./functions_limit_side";
import {ConstrainValues} from "./constrain_value";
import {limitAngle} from "./functions_limit_angle";

//Valid the windows
export const validateWindows = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Reset any errors
    renderData.error = initErrorPoint();

    //Revert any windows to PW if the shape is not possible
    validateWindowShapes(renderData);

    //If there's more than one window
    if(renderData.window.length >1){
        validatePosts(renderData);
    }

    //Check for sides less than the minimum allowed
    limitSide(constrainValues, renderData);

    //Check for angles less than the allower minimum
    limitAngle(constrainValues, renderData);
}

//Check if a window is valid, otherwise revert it to a panel window
const validateWindowShapes = (renderData: RenderData) =>{

    //Iterate through all the windows and revert them to PW if they're invalid
    renderData.window.forEach(window =>{
        
        //Check if the window is valid
        let valid = true;
        switch(window.data.product_type.value){
            case "Factory Arch":
                valid = validFactoryArch(window, renderData);
            break;
            case "L 1/4 Circle":
                valid = validLeftHalfArc(window);
            break;
            case "L Rake":
                valid = validLeftRake(window);
            break;
            case "L Straight Leg Arch":
                valid = validLeftStraightArc(window);
            break;
            case "L Triangle":
                valid = validLeftTriangle(window);
            break;
            case "Peak":
                valid = validPeak(window, renderData);
            break;
            case "R 1/4 Circle":
                valid = validRightHalfArc(window, renderData);
            break;
            case "R Rake":
                valid = validRightRake(window, renderData);
            break;
            case "R Straight Leg Arch":
                valid = validRightStraightArc(window, renderData);
            break;
            case "R Triangle":            
                valid = validRightTriangle(window, renderData);
            break;
            case "Straight Leg Arch":
                valid = validStraightArc(window, renderData);
            break;
        }

        //Revert it to a panel window, if it's not a valid window
        if(!valid) revertToPW(renderData, window);        
    })
}

//Is it a valid factory arch
const validFactoryArch = (window: WindowFragment, renderData: RenderData): boolean =>{
    if(!canArc(window)) return false;
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid Left 1/4 Circle
const validLeftHalfArc = (window: WindowFragment): boolean =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    return true;
}

//Is it a valid left rake
const validLeftRake = (window: WindowFragment): boolean =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    return true;
}

//Is it a valid left straight leg arch
const validLeftStraightArc = (window: WindowFragment): boolean =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    return true;
}

//Is it a valid left triangle
const validLeftTriangle = (window: WindowFragment): boolean =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    return true;
}

//Is it a valid peak
const validPeak = (window: WindowFragment, renderData: RenderData): boolean =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid right 1/4 circle
const validRightHalfArc = (window: WindowFragment, renderData: RenderData) =>{
    if(!isTop(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid right rake
const validRightRake = (window: WindowFragment, renderData: RenderData) =>{
    if(!isTop(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid right straight leg arch
const validRightStraightArc = (window: WindowFragment, renderData: RenderData) =>{
    if(!isTop(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid right triangle
const validRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    if(!isTop(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Is it a valid straight leg arch
const validStraightArc = (window: WindowFragment, renderData: RenderData) =>{
    if(!isTop(window)) return false;
    if(!isLeft(window)) return false;
    if(!isRight(window, renderData)) return false;
    return true;
}

//Determine if a window is bordering the left or not
export const isLeft = (window: WindowFragment): boolean =>{
    if(window.model.line[Segment.LEFT].x1 !== 0){
        return false;
    }
    return true;
}

//Determine if a window is bordering the right
export const isRight = (window: WindowFragment, renderData: RenderData): boolean =>{
    if(window.model.line[Segment.RIGHT].x1 !== renderData.viewport.width){
        return false;
    }
    return true;
}

//Determine if a window is bordering the top
export const isTop = (window: WindowFragment): boolean =>{
    if(window.model.line[Segment.TOP].y1 !== 0){
        return false;
    }
    return true;
}

//Determine if a window can be an arc or not
export const canArc = (window: WindowFragment): boolean =>{    
    const width = window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1;
    const height = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;
    
    //Constrain for factory arch
    if(height > Math.round(width /2)){
        return false;
    }

    return true;
}

//Determine if a window can be a circle or not
export const canCircle = (window: WindowFragment, renderData: RenderData): boolean =>{
    const width = window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1;
    const height = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;
    
    //Constrain for Circle
    if(height !== width){
        return false;
    }
    
    if(renderData.window.length >1){
        return false;
    }

    return true;
}    