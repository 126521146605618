import {Fragment, useState, useEffect} from "react";
import {RecordData} from "../render/render_components/render_data/config_values";

interface DropDownProps{   
    id_item: string; //ID of the accordion item
    id_object: string; //Identifier for the drop down control
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;
    parameter: Map<string, RecordData>    
    id_select?: number //ID of the selected option
}

const ControlDropDown = ({        
        id_item,
        id_object,
        DataHandler,
        parameter,
        id_select
    }: DropDownProps) =>{            
    
    const [selectValue, setSelectValue] = useState(-1);
    
    const handleChange = (key_id: number, value: string) => {        
        DataHandler(id_item, id_object, "", value, key_id);
        setSelectValue(key_id);
    }

    useEffect (() =>{
        if(id_select !== undefined && id_select >=0){
            setSelectValue(id_select);
        }
    }, [id_select]);
    
    return (            
        <Fragment>
            <select 
                    className="form-select"                     
                    value={selectValue}                    
                    onChange={(event) =>{
                        const selectedOption = event.target.options[event.target.selectedIndex];
                        const key_id = Number(selectedOption.value);
                        const description = selectedOption.text;

                        handleChange(key_id, description);
                    }}
                >                
                {Array.from(parameter.values()).map(param => (
                    <option                         
                        key={String(param.id)} 
                        value={param.id}                        
                    >                                        
                        {param.description}
                    </option>        
                ))}
            </select>
        </Fragment>
    );
};

export default ControlDropDown;